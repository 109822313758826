@import "../../node_modules/rsuite/styles/index.less";

// disable features
@enable-ie-polyfill: false;

// color palette
@primary-color: #2c4154;

// fonts
@font-family-base: "Poppins", sans-serif;
@font-size-base: 14px;

h2 {
  margin-bottom: 20px;
}

html,
body,
#root {
  overflow-x: hidden;
}

// .rs-table
.cursor-pointer {
  cursor: pointer;
}
